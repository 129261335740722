/**
 * *Route control
 * @Author: Lyle
 * @Date: 2018-01-01 14:53:36
 * @Last Modified by: Lyle
 * @Last Modified time: 2018-05-22 19:43:49
 */

import router from '../router/index'
import store from '../store/index'
import getSessionid from './getSessionid'

/* Before route jump */

router.beforeEach((to, from, next) => {
	store.commit('clearToken') // Cancel request
	// let {requiresAuth, description, keywords, title} = to.meta;
	store.commit('changeValue', {
		type: 'leftMenu',
		val: to.path
	})

	next()
})
