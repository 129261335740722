import axios from './baseUrl'

export default {
	/**
	 * login
	 * @param data {account,password,accountType} accountType[Enum.value] (FormData)
	 *
	 * @returns {AxiosPromise<any>}
	 */
	login: (data) => axios.post('/admin/v1/authentication/password', data),
	/**
	 * logout
	 * @param data {account,password,accountType} accountType[Enum.value] (FormData)
	 *
	 * @returns {AxiosPromise<any>}
	 */
	logout: (data) => axios.post('/admin/v1/authentication/logout', data),
	/**
	 * signUp
	 * @param data {firstName,lastName,password,confirmPassword,email,mobile,communicationType} communicationType[Enum.value]
	 *
	 * @returns {AxiosPromise<any>}
	 */
	signUp: (data) => axios.post('/v1/signup', data),
	/**
	 * Registration code verification
	 * @param data {signupId,code} (FormData)
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	signupValidCode: (data) => axios.post('/v1/signup/valid-code', data),
	/**
	 *
	 * @param data {signupId,communicationType} (FormData)
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	signupResend: (data) => axios.post('/v1/signup/resend', data),

	/**
	 * forgetPassword
	 * @param data {contact, communicationType} accountType[Enum.value] (FormData)
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	forgetPassword: (data) =>
		axios.post('/admin/v1/account/forget-password', data),

	/**
	 *
	 * @param data {userId, code} (FormData)
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	accountValidCode: (data) =>
		axios.post('/admin/v1/account/valid-code', data),
	/**
	 *
	 * @param data {code, userId, password, confirmPassword}
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	resetPassword: (data) => axios.put('/admin/v1/account/reset-password', data)
}
