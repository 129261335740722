import axios from './baseUrl'
import login from './auth'
import psp from './psp'
import wordpressAuth from './wordpressAuth'
export default {
	...login,
	...psp,
	...wordpressAuth,
	/**
	 * Public method-POST
	 * @param data =>  {}
	 *
	 * @returns {AxiosPromise<any>}
	 */
	axiosPost: (url, data) => axios.post(url, data),
	/**
	 * Public method-GET
	 * @param data =>  {}
	 *
	 * @returns {AxiosPromise<any>}
	 */
	axiosGet: (url, params) => axios.get(url, { params: params }),
	/**
	 * Public method-PUT
	 * @param data =>  {}
	 *
	 * @returns {AxiosPromise<any>}
	 */
	axiosPut: (url, data) => axios.put(url, data),
	/**
	 * Public method-delete
	 * @param data =>  {}
	 *
	 * @returns {AxiosPromise<any>}
	 */
	axiosDetele: (url, data) => axios.delete(url, { data })
}
