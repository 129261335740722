import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		token: '',
		showPage: '', // Landing page === Controls which page is displayed
		leftMenu: '', //Save the current page path
		cancelTokenArr: [], //Store interface request
		openMobileMenu: true,
		pspPageObj: {},
		appInfo: {}
	},
	mutations: {
		/**
		 * *Uniform change value
		 * @param {String} data.type Type in state
		 * @param {any} data.val Modified value
		 */
		changeValue(state, { module, type, val }) {
			if (module) {
				state[module][type] = val
			} else {
				state[type] = val
			}
		},
		/**
		 * *Switch the route and cancel the interface request on the previous page
		 * @param {src} saved interface
		 *
		 */
		pushToken: (state, src) => {
			state.cancelTokenArr.push(src.cancelToken)
		},
		clearToken: (state) => {
			state.cancelTokenArr.forEach((v) => {
				if (v) {
					v()
				}
			})
			state.cancelTokenArr = []
		}
	},
	actions: {},
	modules: {}
})
