<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
export default {
    name: 'app',
    computed: {},
    created() {
        // Uncomment for debugging
        //debugger;
        //Read the status information in sessionStorage during page loading
        if (sessionStorage.getItem('store')) {
            this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
        }
        //Save vuex information to sessionStorage during page refresh
        window.addEventListener('beforeunload', () => {
            this.$store.state.cancelTokenArr = []
            sessionStorage.setItem('store', JSON.stringify(this.$store.state))
        })
    },
}
</script>
<style lang="less">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

[v-cloak] {
    display: none;
}
</style>
