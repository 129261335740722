import axios from './baseUrl'
import api from './index'

export default {
	/**
	 * get psp list
	 * @param data {} accountType[Enum.value] (FormData)
	 *
	 * @returns {AxiosPromise<any>}
	 */
	getPspList: (params) => axios.get('/admin/v1/psp/list', { params: params }),
	/**
	 * delete psp
	 * @param data {} accountType[Enum.value] (FormData)
	 *
	 * @returns {AxiosPromise<any>}
	 */
	deletePspItem: (appId) => axios.delete(`/admin/v1/psp/edit/${appId}`),

	/**
	 * editPspDetail
	 *  * @param data =>  {name,challengeCode,logo,color}
	 *
	 * @returns {AxiosPromise<any>}
	 */
	editPspDetail: (data) => axios.post(`/admin/v1/psp/edit/app-detail`, data),

	/**
	 * viewPspDetail
	 *  * @param appId
	 *
	 * @returns {AxiosPromise<any>}
	 */
	viewPspDetail: (appId) =>
		axios.get(`/admin/v1/psp/detail/${appId}/app-detail`),

	/**
	 * uploadFile
	 *  * @param data =>  {}
	 *
	 * @returns {AxiosPromise<any>}
	 */

	uploadFile: (dir, data) => axios.post(`/file/upload/${dir}`, data),

	/**
	 * editOnboard
	 *  * @param data =>  {name,challengeCode,logo,color}
	 *
	 * @returns {AxiosPromise<any>}
	 */
	editOnboard: (data) => axios.post(`/admin/v1/psp/edit/onboard`, data),

	/**
	 * viewOnboard
	 *  * @param appId
	 *
	 * @returns {AxiosPromise<any>}
	 */
	viewOnboard: (appId) => axios.get(`/admin/v1/psp/detail/${appId}/onboard`),

	/**
	 * editHome
	 *  * @param data =>  {name,condition,gosageType,image,homeDosageInfos:{amount,recurrence},homeAdditionalDtos:{title,icon,externalLink}}
	 *
	 * @returns {AxiosPromise<any>}
	 */
	editHome: (data) => axios.post(`/admin/v1/psp/edit/home`, data),

	/**
	 * viewHome
	 *  * @param appId
	 *
	 * @returns {AxiosPromise<any>}
	 */
	viewHome: (appId) => axios.get(`/admin/v1/psp/detail/${appId}/home`),

	/**
	 * editLearnMore
	 *  * @param data =>  {introductionDescription,externalLink,learnMoreInstructionInfos,:{id,order,step,description},learnMoreAdditionalDtos:{id,title,icon,externalLink}}
	 *
	 * @returns {AxiosPromise<any>}
	 */
	editLearnMore: (data) => axios.post(`/admin/v1/psp/edit/learn-more`, data),

	/**
	 * viewLearnMore
	 *  * @param appId
	 *
	 * @returns {AxiosPromise<any>}
	 */
	viewLearnMore: (appId) =>
		axios.get(`/admin/v1/psp/detail/${appId}/learn-more`),

	/**
	 * editDiscover
	 *  * @param data =>  {displayStore,externalLink,discoverAdditionalDtos,:{id,application,description,externalLink,image},discoverFaqInfos:{id,order,answer,question}}
	 *
	 * @returns {AxiosPromise<any>}
	 */
	editDiscover: (data) => axios.post(`/admin/v1/psp/edit/discover`, data),

	/**
	 * viewDiscover
	 *  * @param appId
	 *
	 * @returns {AxiosPromise<any>}
	 */
	viewDiscover: (appId) =>
		axios.get(`/admin/v1/psp/detail/${appId}/discover`),

	/**
	 * editSupport
	 *  * @param data =>  {supportInfoDtos:{hcp,phone,email,icon},supportAddtionalDtos:{title,externalLink,image},supportCommuntityInfos:{title,description,link}}
	 *
	 * @returns {AxiosPromise<any>}
	 */
	editSupport: (data) => axios.post(`/admin/v1/psp/edit/support`, data),

	/**
	 * viewSupport
	 *  * @param appId
	 *
	 * @returns {AxiosPromise<any>}
	 */
	viewSupport: (appId) => axios.get(`/admin/v1/psp/detail/${appId}/support`),

	/**
	 * updatePspStatus
	 *  * @param data =>  {appId,status}
	 *
	 * @returns {AxiosPromise<any>}
	 */
	updatePspStatus: (data) =>
		axios.put(
			`/admin/v1/psp/edit/${data.appId}/status?status=${data.status}`
		),

	/**
	 * editAppStore
	 *
	 *  * @param data =>  {}
	 *
	 * @returns {AxiosPromise<any>}
	 */
	editAppStore: (data) => axios.post(`/admin/v1/psp/edit/app-store`, data),

	/**
	 * viewAppStore
	 *  * @param appId
	 *
	 * @returns {AxiosPromise<any>}
	 */
	viewAppStore: (appId) =>
		axios.get(`/admin/v1/psp/detail/${appId}/app-store`),

	/**
	 * resubmit
	 *  * @param appId
	 *
	 * @returns {AxiosPromise<any>}
	 */
	resubmitAppStore: (appId) =>
		axios.put(`/admin/v1/psp/edit/${appId}/resubmit`),

	/**
	 * Download Snapshots Zip
	 *  * @param appId
	 *
	 * @returns {AxiosPromise<any>}
	 */
	downloadSnapshotsZip: (appId) =>
		axios.get(`/admin/v1/psp/edit/download/${appId}/snapshots`, {
			responseType: 'blob'
		}),

	/**
	 * getDetailAll
	 *
	 * @returns {AxiosPromise<any>}
	 */
	getDetailAll: (appId) => axios.get(`admin/v1/psp/detail/${appId}/all`)
}
