export const routes = [
	{
		path: '/',
		redirect: '/login'
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('views/auth/login')
	},
	{
		path: '/forgot-password',
		name: 'forgot password',
		component: () => import('views/auth/forgot-password')
	},
	{
		path: '/sign-up',
		name: 'SignUp',
		component: () => import('views/auth/sign-up')
	},
	{
		path: '/email-sent',
		name: 'email sent',
		component: () => import('views/auth/email-sent')
	},
	{
		path: '/new-password',
		name: 'new password',
		component: () => import('views/auth/new-password')
	},
	{
		path: '/home',
		name: 'Home',
		component: () => import('views/system/home')
	},
	{
		path: '/health',
		name: 'health',
		redirect: '/health/psp',
		component: () => import('components/layout'),
		children: [
			{
				path: 'psp',
				name: 'psp',
				component: () => import('views/psp/list'),
				meta: {
					title: 'Medication List (PSP)  '
				}
			},
			{
				path: 'analytics',
				name: 'analytics',
				component: () => import('views/analytics/index'),
				meta: {
					title: 'Analytics'
				}
			}
		]
	},
	{
		path: '/create',
		name: 'create',
		redirect: '/create/PSP-details',
		component: () => import('views/psp/create/layout.vue'),
		children: [
			{
				path: 'PSP-details',
				name: 'create-PSP-details',
				component: () => import('views/psp/create/psp-details')
			},
			{
				path: 'onboarding',
				name: 'create-onboarding',
				component: () => import('views/psp/create/onboarding')
			},
			{
				path: 'home',
				name: 'create-home',
				component: () => import('views/psp/create/home')
			},
			{
				path: 'learn-more',
				name: 'create-learn-more',
				component: () => import('views/psp/create/learn-more')
			},
			{
				path: 'discover',
				name: 'create-discover',
				component: () => import('views/psp/create/discover')
			},
			{
				path: 'support',
				name: 'create-support',
				component: () => import('views/psp/create/support')
			},
			{
				path: 'app-store',
				name: 'create-app-store',
				component: () => import('views/psp/create/app-store')
			}
		]
	},

	{
		path: '/:appName',
		name: 'wordpress',
		redirect: '/:appName/login',
		component: () => import('views/wordpress/layout'),
		children: [
			{
				path: 'login',
				name: 'wp-login',
				component: () => import('views/wordpress/login')
			},
			{
				path: 'create-account',
				name: 'wp-create-account',
				component: () => import('views/wordpress/create-account')
			},
			{
				path: 'code-sent',
				name: 'wp-code-sent',
				component: () => import('views/wordpress/code-sent')
			},
			{
				path: 'forgot-password',
				name: 'wp-forgot-password',
				component: () => import('views/wordpress/forgot-password')
			},
			{
				path: 'new-password',
				name: 'wp-new-password',
				component: () => import('views/wordpress/new-password')
			}
		]
	}
]
