import Vue from 'vue'
import axios from 'axios'
import router from '../router/index'
import store from '../store/index'
import qs from 'qs'

axios.defaults.timeout = 150000

axios.defaults.headers.post['Content-Type'] =
	'application/x-www-form-urlencoded'
axios.defaults.headers.put['Content-Type'] =
	'application/x-www-form-urlencoded"'

// axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.crossDomain = true
axios.defaults.withCredentials = true
axios.defaults.baseURL = 'https://api.app.health42.com.au/health'
// axios.defaults.baseURL = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_CONTEXT_PATH
// Add a request interceptor
axios.interceptors.request.use(
	(config) => {
		config.headers['Authorization'] =
			localStorage.getItem('healthToken') || ''

		//Cancel last request
		config.cancelToken = new axios.CancelToken((cancel) => {
			store.commit('pushToken', cancel)
		})

		if (config.url.indexOf('wordpress') > -1) {
			config.headers['App-Id'] = localStorage.getItem('healthAppId') || ''
		}

		if (config.method === 'post' || config.method === 'put') {
			let data = qs.parse(config.data)

			let url = [
				'/authentication/forget/password',
				'/authentication/valid/code',
				'/authentication/reset/password'
			]
			if (url.indexOf(config.url) > -1) {
				config.data = qs.stringify(
					{
						...data
					},
					{ arrayFormat: 'brackets' }
				)
			}
		} else if (config.method === 'get') {
			config.params = {
				...config.params
			}
		}
		return config
	},
	function (error) {
		// request error
		return Promise.reject(error)
	}
)

// Add a response interceptor
axios.interceptors.response.use(
	function (response) {
		console.log(response, 'response')
		// response data
		if (response.data.statusCode === 404) {
		} else if (response.data.statusCode === 401) {
			//token invalidation

			if (response.config.url.indexOf('wordpress') > -1) {
				router.replace('/wordpress/login')
			} else {
				router.replace('/login')
			}
		}
		return response.data
	},
	function (error) {
		console.log(error)
		// response error
		return Promise.reject(error)
	}
)

export default axios
