import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import 'babel-polyfill'
// import "core-js/stable";
// import "regenerator-runtime/runtime";
import api from './api/index'
import 'assets/css/index.less'
import 'lib-flexible'
// import setRem from 'assets/js/rem';
import SvgIcon from './components/svgIcon'
import 'assets/image/svg/index.js'
import './util/auth'
import eventBus from './util/eventBus'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
//Anti-multiple submission
import preventReClick from 'assets/js/preventReClick'

//Vue2Editor
import Vue2Editor from 'vue2-editor'

Vue.prototype.$api = api
Vue.prototype.$eventBus = eventBus
Vue.config.productionTip = false
// Register to global
Vue.component('svg-icon', SvgIcon)
Vue.use(ElementUI)
Vue.use(Vue2Editor)

Vue.filter('filterFileName', function (value) {
	if (!value) return ''
	value = value.toString()
	return value.slice(value.lastIndexOf('/') + 1)
})

Vue.filter('filterHtml', function (value) {
	if (!value) return ''
	return value.replace(/<[^>]*>/g, '')
})

new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount('#app')
