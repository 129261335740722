import axios from './baseUrl'

export default {
	/**
	 * get authentication app
	 *
	 * @returns {AxiosPromise<any>}
	 */
	getAuthApp: (appName) =>
		axios.get(`/wordpress/v1/authentication/app/${appName}`),

	/**
	 * login
	 * @param data {account,password}
	 *
	 * @returns {AxiosPromise<any>}
	 */
	wordpressLogin: (data) =>
		axios.post(
			`/wordpress/v1/authentication/login?account=${data.account}&password=${data.password}`
		),

	/**
	 * forget password
	 * @param data {mobile}
	 *
	 * @returns {AxiosPromise<any>}
	 */
	wordpressForgetPwd: (data) =>
		axios.post(
			`/wordpress/v1/account/forget-password?mobile=${data.mobile}`
		),

	/**
	 * reset password
	 * @param data {code, userId, password, confirmPassword}
	 *
	 * @returns {AxiosPromise<any>}
	 */
	wordpressResetPwd: (data) =>
		axios.put('/wordpress/v1/account/reset-password', data),

	/**
	 * valid code
	 * @param data {code, userId}
	 *
	 * @returns {AxiosPromise<any>}
	 */
	wordpressValidCode: (data) =>
		axios.post(
			`/wordpress/v1/account/valid-code?code=${data.code}&userId=${data.userId}`
		),

	/**
	 * signup
	 * @param data {mobile}
	 *
	 * @returns {AxiosPromise<any>}
	 */
	wordpressSignup: (data) =>
		axios.post(`/wordpress/v1/signup?mobile=${data.mobile}`),

	/**
	 * signup resend
	 * @param data {signupId}
	 *
	 * @returns {AxiosPromise<any>}
	 */
	wordpressSignupResend: (data) =>
		axios.post(`/wordpress/v1/signup/resend?signupId=${data.signupId}`),

	/**
	 * create wordPress
	 * @param data {appId}
	 *
	 * @returns {AxiosPromise<any>}
	 */
	createWordPress: (appId) =>
		axios.post(`/admin/v1/psp/edit/wordpress/${appId}`),

	/**
	 * signup success
	 * @param data {code,confirmPassword,password,signupId}
	 *
	 * @returns {AxiosPromise<any>}
	 */
	wordpressSignupSuccess: (data) =>
		axios.post('/wordpress/v1/signup/success', data),

	/**
	 * signup valid
	 * @param data {signupId,code}
	 *
	 * @returns {AxiosPromise<any>}
	 */
	wordpressSignupValid: (data) =>
		axios.post(
			`/wordpress/v1/signup/valid?signupId=${data.signupId}&code=${data.code}`
		)
}
